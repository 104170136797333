import React, { useState } from 'react';
import { Container, Divider } from '@mui/material';
import ResponsiveAppBar from './Navbar';
import MainContent from './MainContent';
import ServerStatusTable from './ServerStatusTable';
import TSRanking from './TSRanking';
import Footer from './Footer';
import backgroundSVG from './bg.svg'; // Adjust the path according to your project structure


const styles = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    maxWidth: 'none',
    backgroundColor: '#ffffff',
    padding: '0',
    alignContent: 'center'
};

function FrontPage() {
    const [content, setContent] = useState('main');

    return (
        <Container style={styles} sx={{background: `linear-gradient(rgba(255, 255, 255, 0.90), rgba(255, 255, 255, 0.90)), url(${backgroundSVG})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <ResponsiveAppBar setContent={setContent} />
            {content === 'main' ? <MainContent /> : (content === 'server' ? <ServerStatusTable /> : <TSRanking />)}
            <Footer />
        </Container>
    );
}

export default FrontPage;