import React, { useEffect, useState } from 'react';
import { Paper, Box, Button, ButtonGroup } from '@mui/material';

function TSRanking() {
    const [currentUrl, setCurrentUrl] = useState('https://bornpiece.com/ranksystem/stats/index.php');

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
                /*marginTop: '50px',*/
                margin: 'auto',
                width: '100%',
                height: '85vh'
            }}>
            <iframe src={currentUrl}style={{width: '100%', height: '100%'}} title="Content" allowTransparency="true" frameBorder="0"/>
        </Box>
    );
}

export default TSRanking;