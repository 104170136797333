/*
// src/serverConfig.js
import ts from 'ts.json';
import discord from 'discord.json';
import palworld from 'palworld.json';

const serverConfig = [
    { file: ts, type: 'TeamSpeak', mods: ''},
    /!*{ file: discord, type: 'Discord', mods: ''},*!/
    { file: palworld, type: 'PalWorld', mods: ''},
];

export default serverConfig;*/

// src/components/serverConfig.js
const serverConfig = [
    { file: 'json/ts.json', type: 'TeamSpeak', mods: ''},
    /*{ file: 'discord.json', type: 'Discord', mods: ''},*/
    { file: 'json/rust.json', type: 'Rust', mods: 'Vanilla'},
    { file: 'json/css.json', type: 'Counter-Strike: Source', mods: 'only Dust2'},
    { file: 'json/css2.json', type: 'Counter-Strike: Source', mods: 'only Metro'},
    { file: 'json/css3.json', type: 'Counter-Strike: Source', mods: 'Surf'},
    { file: 'json/csgo.json', type: 'Counter-Strike: Global Offensive', mods: 'only Dust2'},
    { file: 'json/cs2.0.json', type: 'Counter-Strike 2', mods: 'only Dust2'},
    { file: 'json/tf2.json', type: 'Team Fortress 2', mods: 'only 2Fort'},
    { file: 'json/dods.json', type: 'Day of Defeat: Source', mods: 'only Avalanche'},
    { file: 'json/cs.json', type: 'Counter-Strike 1.6', mods: 'only Dust2'},
    { file: 'json/dod.json', type: 'Day of Defeat', mods: 'only Avalanche'},
    { file: 'json/tfc.json', type: 'Team Fortress Classic', mods: 'only 2Fort'},
    /*{ file: 'json/l4d.json', type: 'Left 4 Dead', mods: 'Versus'},*/
    { file: 'json/palworld.json', type: 'PalWorld', mods: 'Vanilla'},
];

export default serverConfig;
