import React from 'react';
import { Typography, Box } from '@mui/material';

function Footer() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', color: 'black', marginTop: 'auto', marginBottom: '30px', marginTop: '30px'}}>
            <Box>
                <Typography variant="body1" sx={{color: "#1B6297"}}>© BornPiece 2006 - 2024</Typography>
            </Box>
        </Box>
    );
}

export default Footer;