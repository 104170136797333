import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import ShieldIcon from '@mui/icons-material/Shield';
import HomeIcon from '@mui/icons-material/Home';
import StorageIcon from '@mui/icons-material/Storage';
import BarChartIcon from '@mui/icons-material/BarChart';
import LanguageIcon from '@mui/icons-material/Language';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';

const pages = [
    { name: 'Home', content: 'main', icon: <HomeIcon sx={{ marginRight: '1px', transform: 'scale(0.8)', justifyItems: 'center' }}/> },
    { name: 'Serverstatus', content: 'server', icon: <StorageIcon sx={{ marginRight: '1px', transform: 'scale(0.8)', alignItems: 'center' }}/> },
    { name: 'TS-Ranking', content: 'tsranking', icon: <BarChartIcon sx={{ marginRight: '1px', transform: 'scale(0.8)', alignItems: 'center' }} />, link: 'https://tsrank.bornpiece.com/' },
/*
    { name: 'Hlstats', content: 'hlstats', icon: <EmojiEventsIcon sx={{ marginRight: '1px', transform: 'scale(0.8)', alignItems: 'center' }} />, link: 'https://bornpiece.gameme.com/' },
    { name: 'SourceBans', content: 'sb', icon: <GpsFixedIcon sx={{ marginRight: '1px', transform: 'scale(0.8)', alignItems: 'center' }} />, link: 'https://sb.bornpiece.com/' },
    { name: 'AMXBans', content: 'amxb', icon: <LocationSearchingIcon sx={{ marginRight: '1px', transform: 'scale(0.8)', alignItems: 'center' }} />, link: 'https://amxb.bornpiece.com/'},
*/
    { name: 'Impressum', content: 'main', icon: <LanguageIcon sx={{ marginRight: '1px', transform: 'scale(0.8)', alignItems: 'center' }} /> },
];

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar({ setContent }) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static" sx={{backgroundImage: '-webkit-linear-gradient(45deg, #1B6297, #2E9FE6 80%)'}}>
            <Container maxWidth="xl" >
                <Toolbar disableGutters >
                    <ShieldIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        /*href="#app-bar-with-responsive-menu"*/
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            /*fontFamily: 'monospace',*/
                            fontWeight: 700,
                            /*letterSpacing: '.3rem',*/
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        BORNPIECE
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                            <MenuItem key={page.name} onClick={() => setContent(page.content)}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {page.icon}
                                    <Typography>{page.name}</Typography>
                                </Box>
                            </MenuItem>
                        ))}
                        </Menu>
                    </Box>
                    <ShieldIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        /*href="home"*/
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            /*fontFamily: 'monospace',*/
                            fontWeight: 700,
                            /*letterSpacing: '.3rem',*/
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        BORNPIECE
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                onClick={() => {
                                    if (page.link) {
                                        window.open(page.link, '_blank');
                                    } else {
                                        setContent(page.content);
                                    }
                                }}
                                /*onClick={() => setContent(page.content)}*/
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {page.icon}
                                    {page.name}
                                </Box>
                            </Button>
                        ))}
                    </Box>

                    {/*<Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>*/}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;