import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import useFetchData from './useFetchData';
import {faTeamspeak} from "@fortawesome/free-brands-svg-icons";
import {faDiscord} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import printChannels from "./printChannels";
import TeamSpeakOverview from "./printChannels";
import PrintChannels from "./printChannels";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import pwicon from './gameicons/pw-icon.png';
import csicon from './gameicons/cs-icon.png';
import cssicon from './gameicons/css-icon.png';
import csgoicon from './gameicons/csgo-icon.png';
import cs2icon from './gameicons/cs2-icon.png';
import rusticon from './gameicons/rust-icon.png';
import tfcicon from './gameicons/tfc-icon.png';
import tf2icon from './gameicons/tf2-icon.png';
import dodicon  from './gameicons/dod-icon.png';
import dodsicon from './gameicons/dods-icon.png';
import ts3icon from './gameicons/ts3-icon.png';



const serverTypeIcons = new Proxy({
    /*'TeamSpeak': <FontAwesomeIcon icon={faTeamspeak} style={{marginRight: '5px', transform: 'scale(1.4)', color: '#1B6297' }}/>,*/
    'TeamSpeak': <img src={ts3icon} alt="ts3-icon" style={{ transform: 'scale(0.7)' }}/>,
    'Discord': <FontAwesomeIcon icon={faDiscord} style={{marginRight: '5px', transform: 'scale(1.4)', color: '#1B6297' }}/>,
    'PalWorld': <img src={pwicon} alt="pw-icon" style={{ transform: 'scale(0.7)' }}/>,
    'Counter-Strike 1.6': <img src={csicon} alt="cs-icon" style={{ transform: 'scale(0.7)' }}/>,
    'Counter-Strike: Source': <img src={cssicon} alt="css-icon" style={{ transform: 'scale(0.7)' }}/>,
    'Counter-Strike: Global Offensive': <img src={csgoicon} alt="csgo-icon" style={{ transform: 'scale(0.7)' }}/>,
    'Counter-Strike 2': <img src={cs2icon} alt="cs2-icon" style={{ transform: 'scale(0.7)' }}/>,
    'Rust': <img src={rusticon} alt="rust-icon" style={{ transform: 'scale(0.7)' }}/>,
    'Team Fortress Classic': <img src={tfcicon} alt="tfc-icon" style={{ transform: 'scale(0.7)' }}/>,
    'Team Fortress 2': <img src={tf2icon} alt="tf2-icon" style={{ transform: 'scale(0.7)' }}/>,
    'Day of Defeat': <img src={dodicon} alt="dod-icon" style={{ transform: 'scale(0.7)' }}/>,
    'Day of Defeat: Source': <img src={dodsicon} alt="dods-icon" style={{ transform: 'scale(0.7)' }}/>

}, {
    get: function (target, name) {
        return target.hasOwnProperty(name) ? target[name] : <SportsEsportsIcon style={{marginRight: '5px', transform: 'scale(1.2)', color: '#1B6297' }}/>;
    }
});

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        /*backgroundImage: '-webkit-linear-gradient(45deg, #1B6297, #2E9FE6 80%)',*/
        backgroundColor: '#1B6297',
        /*backgroundColor: theme.palette.common.black,*/
    },
}));


export default function ServerStatusTable() {
    const servers = useFetchData();

    return (
        <Box sx={{maxWidth: '70%', margin: 'auto', marginTop:'30px'}}>
            <Typography
                variant="h2"
                sx={{
                    fontWeight: 'bold',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundImage: '-webkit-linear-gradient(45deg, #1B6297, #2E9FE6 80%)',
                    display: 'flex',
                    justifyContent: 'center',
                    /*marginBottom: '30px'*/
                }}
            >
                Serverstatus
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '30px',
                        whiteSpace: 'pre-wrap',
                        color: '#1B6297'
                    }}>
                    Use <Typography sx={{color: 'red',fontWeight: 'bold',}}>bornpiece.com</Typography> to connect.
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{backgroundImage: '-webkit-linear-gradient(45deg, #1B6297, #2E9FE6 80%)'}}>
                                <TableCell sx={{color: 'white'}}></TableCell>
                                <TableCell sx={{color: 'white', paddingLeft: '4px'}}>Servertype</TableCell>
                                <TableCell sx={{color: 'white'}}>Mods</TableCell>
                                <TableCell sx={{color: 'white'}}>Map</TableCell>
                                {/*<TableCell align="left" sx={{color: 'white'}}>Servername</TableCell>*/}
                                <TableCell align="center" sx={{color: 'white'}}>Port</TableCell>
                                <TableCell align="center" sx={{color: 'white'}}>Password</TableCell>
                                <TableCell align="left" sx={{color: 'white'}}>User</TableCell>
                                <TableCell align="center" sx={{color: 'white'}}>Maxplayers</TableCell>
                                {/*<TableCell align="center" sx={{color: 'white'}}>Connect</TableCell>*/}
                                <TableCell align="center" sx={{color: 'white'}}>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {servers.map((server, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.04)' : 'white' }}
                                >
                                    <TableCell align="center"
                                               sx={{paddingRight: '1px'}}>{serverTypeIcons[server.type]}</TableCell>
                                    <TableCell align="left" sx={{paddingLeft: '4px'}}>{server.type}</TableCell>
                                    <TableCell align="left">{server.mods}</TableCell>
                                    <TableCell align="left">{server.map}</TableCell>
                                    {/*<TableCell align="left">{server.name}</TableCell>*/}
                                    <TableCell align="right">{server.queryPort}</TableCell>
                                    <TableCell align="center">{server.password ? 'Yes' : 'No'}</TableCell>
{/*                                    <TableCell align="left">
                                        {server.type === 'TeamSpeak' ? server.players.length : server.numplayers}
                                        {server.players.length > 0 && (
                                            <BootstrapTooltip TransitionComponent={Zoom} title={server.players.map(player => <div>{player.name}</div>)} placement={'bottom'}>
                                                <InfoIcon fontSize="small" style={{ marginLeft: '3px', color: '#1B6297', transform: 'scale(0.8)' }} />
                                            </BootstrapTooltip>
                                        )}
                                    </TableCell>*/}
                                    <TableCell align="left">
                                        {server && server.type === 'TeamSpeak' && server.players ? server.players.length : server.numplayers}
                                        {server && server.players && server.players.length > 0 && (
                                            <BootstrapTooltip TransitionComponent={Zoom} title={server.players.map(player => <div>{player.name}</div>)} placement={'bottom'}>
                                                <InfoIcon fontSize="small" style={{ marginLeft: '3px', color: '#1B6297', transform: 'scale(0.8)' }} />
                                            </BootstrapTooltip>
                                        )}
                                    </TableCell>
                                    <TableCell align="center">{server.maxplayers}</TableCell>
                                    {/*<TableCell align="center">{server.connect}</TableCell>*/}
                                    <TableCell align="center"
                                               style={{color: JSON.stringify(server).includes("Failed all 2 attempts") ? 'red' : 'green'}}>
                                        {JSON.stringify(server).includes("Failed all 2 attempts") ? 'Offline' : 'Online'}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
{/*            <Box sx={{display: 'flex', justifyContent: 'right', marginTop: '30px'}}>
                <iframe src="https://discord.com/widget?id=553083758895693844&theme=dark" width="350" height="500"
                        allowTransparency="true" frameBorder="0"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
                </iframe>

            </Box>
            <Box sx={{display: 'flex', justifyContent: 'left', marginTop: '30px'}}>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{backgroundImage: '-webkit-linear-gradient(45deg, #1B6297, #2E9FE6 80%)'}}>
                                    <TableCell sx={{color: 'white'}}>
                                        <FontAwesomeIcon icon={faTeamspeak} style={{marginRight: '5px', transform: 'scale(1.4)', color: '#FFFFFf' }}/>
                                        TeamSpeak Overview
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <PrintChannels/>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>*/}
        </Box>
    );
}

