/*
// src/components/useFetchData.js
import { useState, useEffect } from 'react';
import serverConfig from './serverConfig'; // Fixed import statement

export default function useFetchData() {
    const [servers, setServers] = useState([]);

    const fetchData = () => {
        const fetchedData = serverConfig.map(server => ({
            ...server.file,
            type: server.type,
            mods: server.mods
        }));
        setServers(fetchedData);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return servers;
}*/

// src/components/useFetchData.js
import { useState, useEffect } from 'react';
import serverConfig from './serverConfig';

export default function useFetchData() {
    const [servers, setServers] = useState([]);

    const fetchData = async () => {
        const fetchedData = await Promise.all(serverConfig.map(async server => {
            const response = await fetch(process.env.PUBLIC_URL + '/' + server.file);
            const data = await response.json();
            return {
                ...data,
                type: server.type,
                mods: server.mods
            };
        }));

        setServers(fetchedData);
    };

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 60000); // Fetch data every 60 seconds

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []);


/*
    useEffect(() => {
        fetchData();
    }, []);
*/

    return servers;
}