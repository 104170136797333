import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { faTeamspeak, faDiscord } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function MainContent() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            maxWidth: '70%',
            margin: 'auto', // This centers the box horizontally
        }}>
            <ImageBox />
            <ContentBox />
        </Box>
    );
}

function ContentBox() {
    return (
        <Box sx={{flexBasis: '50%'}}>
            <Typography
                variant="h2"
                sx={{
                    fontWeight: 'bold',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundImage: '-webkit-linear-gradient(45deg, #1B6297, #2E9FE6 80%)',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '10px'
                }}
            >
                Welcome to BornPiece
            </Typography>
            <Typography variant="h5" sx={{color: "#1B6297", display: 'flex', justifyContent: 'center', marginBottom: '50px'}}>
                Join the Thrill. Be Part of Our Gaming Community!
            </Typography>

            <Box sx={{display: 'flex', justifyContent: 'center', transform: 'scale(1.1)'}}>
                <Button variant="contained" color="primary" href={'ts3server://bornpiece.com'} target="_blank" sx={{mr: 2}}>
                    <FontAwesomeIcon icon={faTeamspeak} style={{marginRight: '8px'}}/>
                    TeamSpeak
                </Button>
                <Button variant="contained" color="primary" href={'https://discord.gg/y88ffQAY9H'} target="_blank" sx={{backgroundColor: '#7289DA', '&:hover': {backgroundColor: '#5865F2'}}}>
                    <FontAwesomeIcon icon={faDiscord} style={{marginRight: '8px'}}/>
                    Discord
                </Button>
            </Box>
        </Box>
    );
}

function ImageBox() {
    return (
        <Box sx={{
            flexBasis: '50%',
            display: 'flex',
            marginBottom: '50px',
            /*backgroundImage: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'*/
        }}>
            <img src="https://bornpiece.com/assets/images/wappenw.png" alt="description_of_image" />
        </Box>
    );
}

export default MainContent;